import React, { Component } from "react";
import Logo from "./Logo";
import posed from "react-pose";
import { Link } from "gatsby";
import Footer from "react-bulma-components/lib/components/footer";
import Columns from "react-bulma-components/lib/components/columns";
import Container from "react-bulma-components/lib/components/container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons/faEnvelope";
import { faFacebookF } from "@fortawesome/free-brands-svg-icons/faFacebookF";
import { faInstagram } from "@fortawesome/free-brands-svg-icons/faInstagram";
import { faTwitter } from "@fortawesome/free-brands-svg-icons/faTwitter";
import classNames from "classnames/bind";
import styles from "./Footer.module.scss";
import NewsletterSignup from "components/Footer/NewsletterSignup";
import resolveLinkById from "utils/resolveLinkById";
const cx = classNames.bind(styles);

// You can create keyframes for springs, trails and transitions

const overlayConfig = {
  showing: {
    opacity: 1,
    visibility: "visible",
  },
  hiding: {
    opacity: 0,
    transition: { delay: 100 },
    visibility: "hidden",
  },
  initialPose: "hiding",
};

const FormOverlay = posed.figure(overlayConfig);

export default class SiteFooter extends Component {
  state = { newsletterShowing: false, newsletterVisibility: "hidden" };

  makeNewsletterInvisible = () => {
    clearTimeout(this.showingtimeout);
    this.showingtimeout = setTimeout(() => {
      this.setState({
        newsletterVisibility: "hidden",
      });
    }, 400);
  };

  hideNewsletter = () => {
    if (!this.state.newsletterShowing) {
      return;
    }
    this.setState({ newsletterShowing: false }, this.makeNewsletterInvisible);
  };

  showNewsletter = () => {
    if (this.state.newsletterShowing) {
      return;
    }
    this.setState({ newsletterShowing: true, newsletterVisibility: "visible" });
  };

  toggleNewsletter = () => {
    this.setState(
      { newsletterShowing: !this.state.newsletterShowing },
      (state) => {
        if (!state.newsletterShowing) {
          this.makeNewsletterInvisible();
        }
      }
    );
  };

  render() {
    const theme = this.props.theme || `black`;
    const { data } = this.props;
    let { globals, menu, footer } = data;
    let masterList = [];

    menu.data.body.forEach((item) => {
      let list = item.items.map((item) => ({
        link: item.link,
        label: item.link_label,
        manual_page_link: item.manual_page_link,
      }));
      list.unshift({
        label: item.primary.heading,
        link: item.primary.headling_link,
      });
      masterList.push({ id: item.primary.heading.text, list });
    });

    footer.data.body.forEach((item) => {
      let list = item.items.map((item) => ({
        link: item.link,
        label: item.link_label,
        manual_page_link: item.manual_page_link,
      }));
      list.unshift({
        label: item.primary.heading,
        link: item.primary.headling_link,
      });
      if (
        masterList.filter((mlItem) => mlItem.id === item.primary.heading.text)
          .length > 0
      ) {
        list.shift();
        let ml = masterList.filter(
          (mlItem) => mlItem.id === item.primary.heading.text
        )[0];

        ml.list = ml.list.concat(list);
        ml.column = item.primary.column;
      }
    });

    let footerlist = [
      { id: "1", list: [] },
      { id: "2", list: [] },
      { id: "3", list: [] },
    ];

    masterList.forEach((item) => {
      let chosen = footerlist.filter((flitem) => flitem.id === item.column)[0];
      if (chosen && chosen.list) {
        chosen.list.push(item.list);
      }
    });

    return (
      <Footer
        className={`section ${theme} ${cx({
          footer: true,
          [theme]: true,
        })}`}
      >
        <Container>
          <Columns multiline>
            <Columns.Column
              className={styles.footerColumn}
              mobile={{ size: 12 }}
              tablet={{ size: 4 }}
            >
              {footerlist[0].list.map((list, idx) => {
                return (
                  <ul key={`footer-0-list-${idx}`} className={styles.links}>
                    {list.map((item, idx) => {
                      if (!item.link && !item.manual_page_link) {
                        return null;
                      }
                      return (
                        <li key={`footer-0-${idx}`}>
                          {item.link && (
                            <Link
                              to={resolveLinkById(item.link.raw.id).slug}
                              title={resolveLinkById(item.link.raw.id).title}
                            >
                              {item.label &&
                              item.label.text &&
                              item.label.text.length > 0
                                ? item.label.text
                                : resolveLinkById(item.link.raw.id).title}
                            </Link>
                          )}
                          {item.manual_page_link && (
                            <Link
                              to={item.manual_page_link}
                              title={item.label.text}
                            >
                              {item.label.text}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                );
              })}
            </Columns.Column>
            <Columns.Column
              className={styles.footerColumn}
              mobile={{ size: 12 }}
              tablet={{ size: 3 }}
            >
              {footerlist[1].list.map((list, idx) => {
                return (
                  <ul key={`footer-1-list-${idx}`} className={styles.links}>
                    {list.map((item, idx) => {
                      if (!item.link && !item.manual_page_link) {
                        return null;
                      }
                      return (
                        <li key={`footer-1-${idx}`}>
                          {item.link && (
                            <Link
                              to={resolveLinkById(item.link.raw.id).slug}
                              title={resolveLinkById(item.link.raw.id).title}
                            >
                              {item.label &&
                              item.label.text &&
                              item.label.text.length > 0
                                ? item.label.text
                                : resolveLinkById(item.link.raw.id).title}
                            </Link>
                          )}
                          {item.manual_page_link && (
                            <Link
                              to={item.manual_page_link}
                              title={item.label.text}
                            >
                              {item.label.text}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                );
              })}
            </Columns.Column>
            <Columns.Column
              className={styles.footerColumn}
              mobile={{ size: 12 }}
              tablet={{ size: 3 }}
            >
              {footerlist[2].list.map((list, idx) => {
                return (
                  <ul key={`footer-2-list-${idx}`} className={styles.links}>
                    {list.map((item, idx) => {
                      if (!item.link && !item.manual_page_link) {
                        return null;
                      }
                      return (
                        <li key={`footer-2-${idx}`}>
                          {item.link && (
                            <Link
                              to={resolveLinkById(item.link.raw.id).slug}
                              title={resolveLinkById(item.link.raw.id).title}
                            >
                              {item.label &&
                              item.label.text &&
                              item.label.text.length > 0
                                ? item.label.text
                                : resolveLinkById(item.link.raw.id).title}
                            </Link>
                          )}
                          {item.manual_page_link && (
                            <Link
                              to={item.manual_page_link}
                              title={item.label.text}
                            >
                              {item.label.text}
                            </Link>
                          )}
                        </li>
                      );
                    })}
                  </ul>
                );
              })}
            </Columns.Column>
            <Columns.Column
              className={cx({ footerColumn: true, last: true })}
              mobile={{ size: 12 }}
              tablet={{ size: 2 }}
            >
              <ul className={cx({ links: true, social: true })}>
                <li>
                  <h3>Stay Connected</h3>
                </li>
                <li>
                  <a href="#newsletter" onClick={this.showNewsletter}>
                    <i className="icon">
                      <FontAwesomeIcon icon={faEnvelope} />
                    </i>
                    <span>E-Newsletter</span>
                  </a>
                </li>
                {globals.data.facebook_url1 &&
                  globals.data.facebook_url1.text &&
                  globals.data.facebook_url1.text.length > 0 && (
                    <li>
                      <a
                        href={globals.data.facebook_url1.text}
                        title="Facebook"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="icon">
                          <FontAwesomeIcon icon={faFacebookF} />
                        </i>
                        <span>Facebook</span>
                      </a>
                    </li>
                  )}
                {globals.data.twitter_handle &&
                  globals.data.twitter_handle.text &&
                  globals.data.twitter_handle.text.length > 0 && (
                    <li>
                      <a
                        href={`https://twitter.com/${globals.data.twitter_handle.text}`}
                        title="Twitter"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="icon">
                          <FontAwesomeIcon icon={faTwitter} />
                        </i>
                        <span>Twitter</span>
                      </a>
                    </li>
                  )}
                {globals.data.instagram_url &&
                  globals.data.instagram_url.text &&
                  globals.data.instagram_url.text.length > 0 && (
                    <li>
                      <a
                        href={`${globals.data.instagram_url.text}`}
                        title="Instagram"
                        rel="noopener noreferrer"
                        target="_blank"
                      >
                        <i className="icon">
                          <FontAwesomeIcon icon={faInstagram} />
                        </i>
                        <span>Instagram</span>
                      </a>
                    </li>
                  )}
              </ul>
              <Link className={styles.logo} to={"/"}>
                <span className="is-sr-only">Home</span>
                <i className="icon">
                  <Logo title="Writers' Trust of Canada" />
                </i>
              </Link>
            </Columns.Column>
          </Columns>
          <NewsletterSignup
            className={styles.newsletter}
            newsletterVisibility={this.state.newsletterVisibility}
            newsletterShowing={this.state.newsletterShowing}
          />
        </Container>
        <FormOverlay
          style={{ visibility: this.state.newsletterVisibility }}
          className={styles.newsletterOverlay}
          pose={this.state.newsletterShowing ? `showing` : `hiding`}
          onClick={this.hideNewsletter}
        />
      </Footer>
    );
  }
}
